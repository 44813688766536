import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from "@angular/fire/storage";
import { first } from 'rxjs/operators';
import { Subject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})

export class ClientService {

  downloadURL;

  constructor(private firestore: AngularFirestore, private storage: AngularFireStorage) { }

  private _refreshImageNeeded$ = new Subject<void>();

  get refreshImageNeeded$() {
    return this._refreshImageNeeded$;
  }

  async addDetails(collectionName: string, data: any) {
    return await this.firestore.collection(collectionName).add(data);

  }

  async updateDetails(collectionName: string, data: any, clientID: string) {
    return await this.firestore.collection(collectionName).doc(clientID).update(data);

  }
  async getClientLists(collectionName: any) {
    return await this.firestore.collection(collectionName).valueChanges({ idField: 'id' }).pipe(first()).toPromise();
  }
  async getClientDetail(collectionName: any, clientId: string) {
    return await this.firestore.collection(collectionName).doc(clientId).valueChanges({ idField: 'id' }).pipe(first()).toPromise();
  }

  async getClientContactDetail(collectionName: any, clientId: string) {
    return await this.firestore.collection(collectionName, ref => ref.where("Client.client_id", "==", clientId)).snapshotChanges();
  }

  async getIndustries(collectionname: string) {
    let getindustry = await this.firestore.collection(collectionname).valueChanges({ idField: 'id' }).pipe(first()).toPromise();
    return getindustry;
  }

  async deleteClient(collectionName: string, id: string) {
    return await this.firestore.collection(collectionName).doc(id).delete();
  }

  async updateProfile(collectionName: any, profileId, data) {
    let update = await this.firestore.collection(collectionName).doc(profileId).update(data);
  }


  async uploadImage(file, path) {
    var n = Date.now();
    const filePath = `HiremeApp/${path}/images/client_logos/${n}`;
    const fileRef = this.storage.ref(filePath);
    const uploadTaskSnapshot = await fileRef.put(file);
    this.downloadURL = await uploadTaskSnapshot.ref.getDownloadURL();
    return this.downloadURL;
  }

}
